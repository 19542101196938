import React from 'react'
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap"
import brainstorming1 from '../../images/brainstorming1.png'
// import Title from '../Globals/Title'

export default function Info() {
    return (
      <section className="py-5">
        <div className="container">
          <Container>
            <Row>
              <Col>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        What is logopwa?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card>
                        <Card.Img variant="top" src={brainstorming1} />
                        <Card.Body>
                          <Card.Title>LOGOPWA</Card.Title>
                          <Card.Text>
                            LOGOPWA is the creation of CEO importance for good quality, creative, and professional logo design. 
                            PWA mean Progressive Web Application 
                          </Card.Text>
                          <Button variant="primary" href='/about'>Go somewhere</Button>
                        </Card.Body>
                      </Card>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        Click me!
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>Hello! I'm another body</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    )
}


          // <Title title="Our Story" />
          // <div className="row">
          //   <div className="col-10 col-sm-8 mx-auto text-center">
          //     <p className="lead text-muted mb-5">Orlando Florida technology company for our local community. Logopwa is local company design to assist our local community in their growth. Logopwa is an inclusive company that promotes human talent and human emphaty </p>
          //     <Link to="/about/">
          //       <button className="btn text-uppercase btn-yellow">
          //         about page
          //       </button>
          //     </Link>
          //   </div>
          // </div>