import React from "react"
import { graphql } from "gatsby"
import { Jumbotron, Button, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import BackgroundSection from "../components/Globals/BackgroundSection"
import Info from "../components/Home/Info"
// import Img from "gatsby-image"
import Services from "../components/Home/Services"



const AboutPage = props => (
  <Layout>
    <SEO title="Home" keywords={["Website"]} />
    <Container>
      <Jumbotron className="AboutJumbotron">
        <h1>Hello, world!</h1>
        <p>
          This is a simple hero unit, a simple jumbotron-style component for
          calling extra attention to featured content or information.
        </p>
        <p>
          <Button href="tel:407-668-9983" variant="primary">
            407-668-9983
          </Button>
        </p>
      </Jumbotron>
      <Services />
      <Info />
      <Container>
        <Jumbotron fluid className="About2Jumbotron">
          <h1>Fluid jumbotron</h1>
          <p>
            This is a modified jumbotron that occupies the entire horizontal
            space of its parent.
          </p>
        </Jumbotron>
      </Container>

    </Container>
  </Layout>
)

export const query = graphql`
         {
           img: file(relativePath: { eq: "brainStorming.png" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_tracedSVG
               }
             }
           }
           services: allContentfulConsultationItem {
             edges {
               node {
                 id
                 title
                 description {
                   description
                 }
                 price
                 category
                 image {
                   fixed(width: 50, height: 50) {
                     ...GatsbyContentfulFixed_tracedSVG
                   }
                 }
               }
             }
           }
         }
       `

export default AboutPage


    // <BackgroundSection
    //   img={data.img.childImageSharp.fluid}
    //   title="Web development services in Orlando"
    //   styleClass="about-background"
    // />